@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
	--main-font: "Nunito";
	--main-color: #edfe3;
	--light-bg: #f7f7f7;
	--light-color: #343434;
	--dark-bg: #1f1f1f;
	--dark-color: #d6d6d6;
	--mode-bg: var(--light-bg);
	--mode-color: var(--light-color);
	--nav-bg: gold;
	
	/* sizes */
	--toastify-toast-width: 400px !important;
	--nav-height: 62px;
	--nav-padding-x: 24px;
	--aside-width: 348px;
	--aside-height: calc(100vh - var(--nav-height));
	--map-heigh: calc(100vh - var(--nav-height));
	--map-width: 100vw;
	--map-width-aside-opened: calc(100vw - var(--aside-width));
	--footer-min-height: 62px;
	--backdrop-filter-blur: blur(18px);
	/* border-radius */
	--card-radius: 5px;
	
	--z-index-max: 2147483647;
	--z-index-toast: var(--z-index-max);
	--z-index-loading: var(--z-index-max);
	--z-index-modal: 2147483646;
	--z-index-backdrop: 2147483645;
	--z-index-dropdown: 2147483644;
}
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	border: 0 solid #e2e8f0;
	-webkit-box-sizing: inherit;
	box-sizing: inherit;
	background-repeat: no-repeat;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
	-webkit-font-smoothing: antialiased;
}
::-moz-selection {
	background: #9db2ff !important;
}

::selection {
	background: #9db2ff !important;
}
html {
	scroll-behavior: smooth;
}
html,body {
	min-height: 100vh;
	font-family: var(--main-font);
	background-color: var(--mode-bg);
	overflow-x: hidden;
}
body {
	cursor: default;
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	print-color-adjust: exact;
	-webkit-print-color-adjust: exact;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
}

picture,
img {
	display: block;
	max-width: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	user-select: none;

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-ms-user-drag: none;
	user-drag: none;
	max-width: 100%;
	/* https://x.com/csswizardry/status/1717841334462005661 */
	height: auto;
	vertical-align: middle;
	font-style: italic;
	background-repeat: no-repeat;
	background-size: cover;
	shape-margin: 0.75rem;
}
#root {
	position: relative;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

audio,
canvas,
embed,
iframe,
img,
object,
svg,
video {
	display: block;
	vertical-align: middle;
}

sup {
	top: unset;
}

[hidden] {
	display: none;
}
[disabled],
.disabled {
	cursor: not-allowed !important;
	opacity: 0.7 !important;
}


.disable-pointer-events,
.disable-pointer-events > * {
	pointer-events: none !important;
}

/* charts */

.highcharts-credits {
	display: none !important;
}
.highcharts-title,
.highcharts-subtitle,
[class^="highcharts"] {
	user-select: unset !important;
}
.chart-container {
	background-color: #ffffff;
	position: relative;
	height: 100%;
	padding: 10px;
	border-radius: var(--card-radius);
}
.chart-pie {
	width: 50%;
}

.flex-full {
	flex: 1 1 100%;
}

.Toastify__toast-container {
	z-index: var(--z-index-toast) !important;
}

.Toastify__close-button {
	@apply flex items-center justify-center !important;
	@apply min-w-9 w-9 h-9 opacity-100 !important;
	@apply rounded-full !important;
	@apply bg-gray-100 hover:bg-gray-300 transition-all duration-300 !important;
	@apply border border-gray-900 !important;
}

.Toastify__toast-body {
	font-size: 18px;
}

.mapboxgl-popup-content{
	padding: 25px 10px 15px !important;
}
.mapboxgl-popup-close-button {
	width: 32px;
  height: 32px;
	font-size: 18px;
}