/*
Font List
Rubik
  - 500 | Normal
  - 400 | Normal

Inter
  - 500 | Normal
  - 300 | Normal
  - 300 | Italic

font-family: arial
font-size: 16px
line-height: 1.6
visibility: hidden
color: black
letter-spacing: 0.35px
word-spacing: 0.05px
font-weight: 300;
*/
@font-face {
  font-family: arial;
  src: local('Arial');
  line-height: 1.6;
  visibility: hidden;
  letter-spacing: 0.35px;
  word-spacing: 0.05px;
}

@font-face {
  font-family: 'arialR';
  src: local('Arial');
  line-height: 1.6;
  visibility: hidden;
  letter-spacing: 0.33px;
}


body {
  font-family: 'Inter', 'arial', 'sans-serif';
  font-weight: 400;
  font-style: normal;
}

h1, h2 {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
}

h1 a, h2 a {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
}

h3, h4, h5, h6 {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
  color: #22293b;
}

h3 a, h4 a, h5 a, h6 a {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
  color: #22293b;
}

th {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
}

p {
  font-family: 'Inter', 'arial', 'sans-serif';
  font-weight: 400;
  font-style: normal;
}

p strong, p b {
  font-family: inherit;
  font-weight: 600;
  font-style: inherit;
}

p i, p em {
  font-family: inherit;
  font-weight: 400;
  font-style: italic;
}

/* Global Font Classes */
.rubik-bold {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 500;
  font-style: normal;
}

.rubik-normal {
  font-family: 'Rubik', 'arialR', 'helvetica';
  font-weight: 400;
  font-style: normal;
}

.inter-bold {
  font-family: 'Inter', 'arial', 'sans-serif';
  font-weight: 500;
  font-style: normal;
}

.inter-normal {
  font-family: 'Inter', 'arial', 'sans-serif';
  font-weight: 400;
  font-style: normal;
}

.inter-normal-italic {
  font-family: 'Inter', 'sans-serif', 'helvetica';
  font-weight: 400;
  font-style: italic;
}