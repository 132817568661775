@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'),
    url('Inter-Medium-subset.woff2') format('woff2'),
    url('Inter-Medium-subset.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), local('Inter-Regular'), 
    url('Inter-Regular-subset.woff2') format('woff2'),
    url('Inter-Regular-subset.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src:  local('Inter RegularItalic'), local('Inter-RegularItalic'), 
    url('subset-Inter-Regular-Italic.woff2') format('woff2'),
    url('subset-Inter-Regular-Italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
