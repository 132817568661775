@font-face {
  font-family: 'Rubik';
  src: local('Rubik Medium'), local('Rubik-Medium'),
      url('subset-Rubik-Medium.woff2') format('woff2'),
      url('subset-Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

